<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/coupons"
        >
          Manajemen Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Detail Kupon
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex
      justify="space-between"
      align="center"
      mb="30px"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Detail Kupon
      </c-heading>
      <c-box>
        <c-button
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpen = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          h="auto"
          min-w="114px"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          as="router-link"
          :to="{
            name: 'superadmin.coupons.edit',
            params: { id: id },
          }"
        >
          <c-image
            :src="require('@/assets/icon-pencil.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Edit
        </c-button>
      </c-box>
    </c-flex>

    <c-box>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kode
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-weight="500"
          font-size="18px"
          line-height="27px"
        >
          {{ item.code }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nama Kupon
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.name }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kuota
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.quota }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Start
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.startAt }} - {{ item.endAt }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Potongan
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ getDiscount(item) }}
        </c-text>
      </c-form-control>
    </c-box>

    <DeleteConfirm
      :content="deletedText"
      :is-open="isOpen"
      @on-delete="onDelete"
    />
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DeleteConfirm from './../delete-confirm.vue'

export default {
  name: 'SuperadminCouponDetail',
  components: { DeleteConfirm },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      item: 'suCoupons/item',
    }),
    id() {
      return this.$route.params.id
    },
    deletedText() {
      return `Anda yakin ingin hapus kupon <br /> “${this.item.code}”?`
    },
  },
  async mounted() {
    this.detailCoupons(this.id)
  },
  methods: {
    ...mapActions({
      detailCoupons: 'suCoupons/detailCouponsSuperadmin',
      deleteCoupons: 'suCoupons/deleteCouponsSuperadmin',
    }),
    getDiscount(item) {
      if (item.type === 'percent') {
        return `${item.totalDiscount}%`
      }
      return `Rp${Number(item.totalDiscount).toLocaleString('id')}`
    },
    closeDialog() {
      this.isOpen = false
    },
    onDelete(state) {
      this.isOpen = state
      if (!this.isOpen) return
      this.deleteCoupons(this.id)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Kupon has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.coupons',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
  },
}
</script>
