var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/coupons"
    }
  }, [_vm._v(" Manajemen Kupon ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Detail Kupon ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Kupon ")]), _c('c-box', [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "h": "auto",
      "min-w": "114px",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "as": "router-link",
      "to": {
        name: 'superadmin.coupons.edit',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Edit ")], 1)], 1)], 1), _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kode ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.code) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Kupon ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kuota ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.quota) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Start ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.startAt) + " - " + _vm._s(_vm.item.endAt) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Potongan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getDiscount(_vm.item)) + " ")])], 1)], 1), _c('DeleteConfirm', {
    attrs: {
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }